import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

class AboutPage extends React.Component {
  render() {

    return (
      <>
        
      </>
    )
  }
}

export default AboutPage
